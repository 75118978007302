import React, { useState } from "react";

import Container from "./style";
import Button from "../Button";
import Contact from "Containers/Views/LandingPage/Modal";

const Header = () => {
  const [isModal, setIsModal] = useState(false);
  return (
    <Container>
      <Contact modal={isModal} toggle={() => setIsModal(!isModal)} />
      <div className="company-name">Alpha Jet</div>
      <Button
        className="learn-more"
        btnName="Learn More"
        onClick={() => setIsModal(!isModal)}
      />
    </Container>
  );
};

export default Header;
