export const emailPattern = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
export const phonePattern = new RegExp(/^[0-9]{10}$/);
export const validPhoneNumber = /^[1-9]{1}[0-9]{9}/;

export const InvalidFirstName = "* Please enter the First Name.";
export const InvalidLastName = "* Please enter the Last Name.";
export const InvalidEmail = "* Please enter valid email address.";
export const InvalidPhone = "* Please enter valid phone number.";
