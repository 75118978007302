import React, { useState } from "react";
import _map from "lodash/map";
import size from "lodash/size";
import _get from "lodash/get";
import { toast } from "react-toastify";

import axios from "axios";
import qs from "qs";
import {
  FlagIcon,
  IndianFlag,
  AlFlag,
  Austria,
  Australia,
  DownArrow,
} from "design/Icons";
import { Row, Col } from "reactstrap";
import Button from "Components/Button";
import { handleValidationContactForm } from "./Validation";
import { COUNTRIES } from "./MockData";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Container, FormLabel, InputField, TestArea } from "./style";

const initialCategoryState = { id: "+1", title: "+1" };

function ContactForm({ className }) {
  const [defaultCategory, setDefaultCategory] = useState(initialCategoryState);
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    message: "",
    phone_code: "-1",
  });

  const [errors, setError] = useState({});

  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormValue({ ...formValue, [name]: value });
    setError({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const validationObj = handleValidationContactForm(formValue);

    if (!validationObj.formIsValid) {
      setError(validationObj.errors);
      return;
    }

    const data = qs.stringify({
      source: "alphajet",
      first_name: formValue.firstName,
      last_name: formValue.lastName,
      email: formValue.email,
      company_title: formValue.companyName,
      phone_code: formValue.phone_code,
      contact_no: formValue.phoneNumber,
      message: formValue.message,
    });

    const config = {
      method: "post",
      url: "https://base.elevationai.com/contact-us/create",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast("Successfully added", { type: "success" });
      })
      .catch((error) => {
        toast(error, { type: "error" });
      });
    clear();
  };

  const clear = () => {
    setFormValue({
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      phoneNumber: "",
      message: "",
      phone_code: "+1",
    });
    setDefaultCategory({ id: "+1", title: "+1" });
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleCommonListChange = (target, type, phone_code) => {
    const value = target && target.value;
    if (value) {
      setDefaultCategory({ id: value, title: value });
      setFormValue({ ...formValue, phone_code: value });
    }
  };

  return (
    <Container className={className}>
      {className && <div className="title-touch">Get In Touch</div>}
      <form onSubmit={handleSubmitForm}>
        <Row>
          <Col md="6">
            <FormLabel>
              First Name<span className="stark-mark">*</span>
            </FormLabel>
            <InputField
              type="text"
              name="firstName"
              onChange={onHandleChange}
              className={errors.firstName && "error-field"}
              value={formValue.firstName}
            />
            <span className="error-message">
              {errors.firstName && errors.firstName}
            </span>
          </Col>
          <Col md="6">
            <FormLabel>
              Last Name<span className="stark-mark">*</span>
            </FormLabel>
            <InputField
              type="text"
              name="lastName"
              onChange={onHandleChange}
              className={errors.lastName && "error-field"}
              value={formValue.lastName}
            />
            <span className="error-message">
              {errors.lastName && errors.lastName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormLabel>
              Email Id<span className="stark-mark">*</span>
            </FormLabel>
            <InputField
              name="email"
              onChange={onHandleChange}
              className={errors.email && "error-field"}
              value={formValue.email}
            />
            <span className="error-message">
              {errors.email && errors.email}
            </span>
          </Col>
          <Col md="6">
            <FormLabel>
              Phone No.<span className="stark-mark">*</span>
            </FormLabel>
            <div className="container-image-input">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                size="sm"
                className="drop-down-Button"
              >
                <DropdownToggle className="drop-down-toggle">
                  <div className="flag-Icon-container">
                    {defaultCategory.title}
                    <img
                      src={DownArrow}
                      alt="Uploading the Image"
                      className="arrow-icon"
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="menu-box">
                  {size(COUNTRIES) &&
                    _map(COUNTRIES, (item, index) => (
                      <DropdownItem
                        className="dropdown-items"
                        key={index}
                        onClick={({ target }) => handleCommonListChange(target)}
                        value={item.title}
                        name="phone_code"
                      >
                        {item.title}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
              <InputField
                type="text"
                name="phoneNumber"
                onChange={onHandleChange}
                className={errors.phoneNumber && "error-field"}
                value={formValue.phoneNumber}
                maxLength={10}
              />
            </div>
            <span className="error-message">
              {errors.phoneNumber && errors.phoneNumber}
            </span>
          </Col>
        </Row>
        {className && (
          <Row>
            <Col md="12">
              <FormLabel>Company Name</FormLabel>
              <InputField
                type="text"
                name="companyName"
                onChange={onHandleChange}
                className={errors.companyName && "error-field"}
                value={formValue.companyName}
              />
              <span className="error-message">
                {errors.companyName && errors.companyName}
              </span>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="12">
            <FormLabel>
              Message<span className="stark-mark">*</span>
            </FormLabel>
            <TestArea
              type="textarea"
              name="message"
              placeholder="Write your message here"
              className={
                errors.message ? "textarea error-text-area" : "textarea"
              }
              value={formValue.message}
              onChange={onHandleChange}
            />
            <span className="error-message">
              {errors.message && errors.message}
            </span>
          </Col>
        </Row>
        <div className="submit-btn">
          <Button
            className="contact-form-btn"
            btnName="Submit"
            type="submit"
            onClick={handleSubmitForm}
          />
        </div>
      </form>
    </Container>
  );
}

export default ContactForm;
