import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 90px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1050;
  background: #ffffffe3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000014;
  opacity: 1;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px;
  /* opacity: 0.9; */
  @media (max-width: 1025px) and (min-width: 550px) {
    padding: 0 70px;
  }
  @media (max-width: 1540px) and (min-width: 320px) {
    padding: 0 40px;
  }

  .company-name {
    font: bold 32px Montserrat;
    color: #8e93ff;
    @media (max-width: 767px) and (min-width: 550px) {
      font: bold 25px Montserrat;
    }
    @media (max-width: 549px) and (min-width: 320px) {
      font: bold 23px Montserrat;
    }
  }

  .learn-more {
    background: transparent linear-gradient(0deg, #b6c9ff 0%, #8b8fff 100%);
    box-shadow: 0px 8px 20px #b4c4ff29;
    font: 500 20px Montserrat;
    cursor: pointer;
    color: #ffffff;
    max-width: 147px;
    width: 100%;
    height: 56px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) and (min-width: 550px) {
      font: 500 17px Montserrat;
      max-width: 120px;
    }
    @media (max-width: 549px) and (min-width: 320px) {
      font: 500 15px Montserrat;
      max-width: 120px;
    }
  }
`;

export default Container;
