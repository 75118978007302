import styled from "styled-components";

export const Wrapper = styled.div`
  .scroll-pre {
    margin-top: 90px;
  }
`;

export const ContactFormHeader = styled.div`
  text-align: center;
  padding: 95px 20px 32px 20px;
  @media (max-width: 900px) and (min-width: 320px) {
    padding: 25px;
  }
  .heading-style {
    font: 600 50px Montserrat;
    color: #2a2a2a;
    opacity: 1;
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 30px;
    }
  }
  .sub-heading {
    font: normal normal medium 24px/29px Montserrat;
    font-size: 24px;
    color: #ababab;
    opacity: 1;
    margin-top: 20px;
    @media (max-width: 672px) and (min-width: 550px) {
      font-size: 16px;
    }
    @media (max-width: 549px) and (min-width: 320px) {
      font-size: 14px;
    }
  }
  .email-info {
    display: flex;
    justify-content: center;
    margin: 15px 0px 25px;
    margin: 32px 0px 0px;
  }
  .email-container {
    width: 40px;
    height: 40px;
    background: transparent linear-gradient(0deg, #b6c9ff 0%, #8b8fff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 8px 20px #b4c4ff29;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
  .email-image-style {
    width: 28px;
    height: 20px;
    display: flex;
  }
  .email-name-style {
    align-items: center;
    display: flex;
    justify-content: center;
    color: #505152;
    font: normal 600 24px Montserrat;
    @media (max-width: 550px) and (min-width: 320px) {
      font-size: 20px;
    }
  }
`;
export const MainContainer = styled.div`
  background: linear-gradient(to right, #8b8fff, #b6c9ff);
  text-align: center;
  position: relative;

  .buttons {
    padding-top: 50px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;

    div {
      background: #99a2ff1a 0% 0% no-repeat padding-box;
      border: 1px solid #ffffff;
      border-radius: 8px;
      width: 250px;
      height: 56px;
      font: 500 16px Montserrat;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .title {
    font: 800 60px Montserrat;
    color: #ffffff;
    margin-bottom: 28px;

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 30px;
    }
  }

  .content {
    font: 500 20px Montserrat;
    color: #ffffff;
    margin-bottom: 31px;
  }

  .image {
    z-index: 1;
    position: relative;
    padding: 0 140px;
    padding-bottom: 25px;

    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0 25px !important;
      padding-bottom: 25px !important;
    }

    /* @media (min-width: 991px) and (max-width: 1200px) {
      padding: 0 60px !important;
      padding-bottom: 25px !important;
    } */

    @media (min-width: 991px) and (max-width: 1500px) {
      padding: 0 230px !important;
      padding-bottom: 25px !important;
    }

    @media (min-width: 1500px) {
      max-width: 86%;
    }
  }

  .grid {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;
export const Container = styled.div`
  margin: auto;
  max-width: 80%;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #8b8b8b0f;
  border-radius: 8px;
  opacity: 1;
  padding: 56px 122px;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 25px;
    max-width: 100%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding: 25px;
  }
  @media (min-width: 767px) and (max-width: 1500px) {
    max-width: 80%;
  }
  @media (min-width: 1500px) {
    max-width: 70%;
  }
  .title-touch {
    margin-top: 25px;
    font: 600 32px Montserrat;
    color: #8e93ff;

    @media (min-width: 320px) and (max-width: 767px) {
      margin-top: 0;
    }
  }
  &&.modal-form {
    background: none;
    box-shadow: none;
    border-radius: none;
    padding: 0 122px;
    max-width: 100%;
    padding-bottom: 40px;

    @media (min-width: 320px) and (max-width: 767px) {
      padding: 25px;
      max-width: 100%;
    }

    .contact-form-btn {
      background: transparent linear-gradient(0deg, #b6c9ff 0%, #8b8fff 100%);
      color: #ffffff;
      background: transparent linear-gradient(0deg, #b6c9ff 0%, #8b8fff 100%);
      box-shadow: 0px 8px 20px #b4c4ff29;
      border: none;
    }
  }
  .contact-form-btn {
    text-align: center;
    font-size: 22px;
    font: medium 22px Montserrat;
    letter-spacing: 0px;
    color: #9298ff;
    border: 1px solid #9298ff;
    border-radius: 8px;
    opacity: 1;
    width: 100%;
    max-width: 200px;
    height: 56px;
    background-color: #ffffff;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 550px) and (min-width: 320px) {
      width: 170px;
      height: 46px;
      font-size: 18px;
    }
  }
  .submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flag-image-style {
    width: 32px;
    height: 21px;
    background: transparent url("img/Group 209.png") 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .flag-Icon-container {
    color: #ababab;
    position: relative;
    bottom: -4px;
    margin-right: 5px;
    padding: 7px;
    width: 87px;
    height: 36px;
    background: #f5f6ff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
  }
  .container-image-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .error-message {
    color: red;
  }
  .drop-down-toggle {
    background-color: #ffffff !important;
    border: none !important;
    padding: 0px !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  .menu-box {
    min-width: 4rem !important;
    padding: 0px !important;
    overflow: auto;
    height: 200px;
  }
  .dropdown-items {
    padding: 0.25rem 0.9rem !important;
  }
  .dropdown-items:focus-visible {
    outline: none !important;
  }
  .dropdown-items:focus {
    outline: none !important;
  }
  .dropdown-items:hover {
    background-color: #a6c3e0 !important;
  }
  .dropdown-items:active {
    background-color: #ffffff !important;
    color: black;
  }

  .drop-down-toggle:focus {
    box-shadow: none !important;
  }
  .arrow-icon {
    width: 11px;
    margin-left: 3px;
  }
  .error-field {
    color: red !important;
    border-bottom: 2px solid red !important;
    ::placeholder {
      color: red;
      opacity: 1;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .textarea {
    width: 100%;
    height: 200px;
    padding: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #ababab;
    border-radius: 8px;
    opacity: 1;
    margin-top: 6px;
    ::placeholder {
      color: #d8d8d8;
      opacity: 1;
    }
  }
  .error-text-area {
    border: 2px solid red;
  }
  .textarea:focus {
    box-shadow: none !important;
  }
  .textarea:focus-visible {
    outline: none !important;
  }
  .textarea::placeholder {
    font-size: 16px;
    color: #d8d8d8;
    opacity: 1;
  }
  input:focus {
    box-shadow: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:focus-visible {
    outline: none;
  }
`;

export const TestArea = styled.textarea`
  .textarea {
    width: 100%;
    height: 200px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #ababab;
    border-radius: 8px;
    opacity: 1;
    ::placeholder {
      color: #d8d8d8;
      opacity: 1;
    }
  }
`;

export const FormLabel = styled.div`
  text-align: left !important;
  font: normal 16px Montserrat;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ababab;
  margin-top: 30px;
  margin-bottom: 0;
  &&:not(:first-child) {
    margin-top: 0px !important;
  }
  .stark-mark {
    color: red;
    margin-left: 10px;
    font-size: 22px;
  }
`;

export const InputField = styled.input`
  width: 100%;
  border: none;
  border-bottom: 2px solid #ababab;
  border-radius: 0px;
  width: 100%;
  text-align: left;
  letter-spacing: 0px;
  color: #9298ff;
  opacity: 1;
  height: 42px;
  font-size: 24px;
  font-family: Montserrat;
  ::placeholder {
    color: #9298ff;
    opacity: 1;
  }
  padding-left: 0px;
`;
