import React, { PureComponent } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import Clearx from "asserts/clearx.svg";

const Close = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 28px;
  right: 40px;
  cursor: pointer;
  z-index: 9999;

  @media (min-width: 320px) and (max-width: 767px) {
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
  }
`;

class ModalStyled extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModal: this.props.modal,
    };
  }

  componentDidUpdate(prevProps) {
    const { modal } = this.props;
    if (modal !== prevProps.modal) {
      this.setState({ isModal: modal });
    }
  }

  // Use for open/close popup
  toggleModal = () => {
    const { toggle } = this.props;
    if (toggle) {
      this.setState({
        isModal: false,
      });
    }
  };

  // Use for close popup after some second
  onReject = () => {
    const { toggle } = this.props;
    this.toggleModal();
    if (toggle) {
      setTimeout(() => {
        toggle();
      }, 100);
    }
  };

  render() {
    const { toggle, content, className, hideCloseIcon } = this.props;
    const { isModal } = this.state;
    return (
      <Modal
        isOpen={isModal}
        toggle={this.onReject}
        className={className}
        modalClassName={className}
      >
        {!hideCloseIcon && toggle && (
          <Close
            src={Clearx}
            onClick={this.onReject}
            className="modal-close-icon"
          />
        )}
        <ModalBody>{content}</ModalBody>
      </Modal>
    );
  }
}

export default ModalStyled;
