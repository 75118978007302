import React from "react";

import Container from "./style";

const Footer = () => {
  const date = new Date();
  return (
    <Container>
      © {date.getUTCFullYear()} by Alpha Jet, Inc. All rights reserved.
    </Container>
  );
};

export default Footer;
