import React from "react";

const Button = ({ btnName, className, onClick, type }) => {
  return (
    <div className={className} onClick={onClick} type={type}>
      {btnName}
    </div>
  );
};

export default Button;
