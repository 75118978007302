import React from "react";

import Header from "Components/Header";
import { GmailIcon } from "design/Icons";
import ContactForm from "./ContactForm";
import Footer from "Components/Footer";
import Button from "Components/Button";
import { LoginPageImage, GridImage } from "design/Icons";
import { Wrapper, ContactFormHeader, MainContainer } from "./style";

const LandingPage = () => {
  return (
    <Wrapper>
      <Header />
      <MainContainer>
        <div className="buttons">
          <div>INTRODUCING ALPHA JET</div>
        </div>
        <div className="title">AI Crypto Trading</div>
        <div className="content">
          Grow your crypto portfolio using proven AI and expert traders.
        </div>
        <img src={LoginPageImage} className="img-fluid image" />
        <img src={GridImage} className="grid" />
      </MainContainer>
      <ContactFormHeader>
        <div className="heading-style">Contact Our Team</div>
        <div className="sub-heading">
          Please fill out the form below and our team will get in touch with you
          soon.
        </div>
        <div className="email-info">
          <div className="email-container">
            <img
              src={GmailIcon}
              alt="Loading the image"
              className="email-image-style"
            />
          </div>

          <div className="email-name-style">info@alphajet.com</div>
        </div>
      </ContactFormHeader>
      <ContactForm />
      <Footer />
    </Wrapper>
  );
};

export default LandingPage;
