export const COUNTRIES = [
  { name: "Afghanistan", title: "+93", code: "AF" },
  { name: "Aland Islands", title: "+358", code: "AX" },
  { name: "Albania", title: "+355", code: "AL" },
  { name: "Algeria", title: "+213", code: "DZ" },
  { name: "AmericanSamoa", title: "+1684", code: "AS" },
  { name: "Andorra", title: "+376", code: "AD" },
  { name: "Angola", title: "+244", code: "AO" },
  { name: "Anguilla", title: "+1264", code: "AI" },
  { name: "Antarctica", title: "+672", code: "AQ" },
  { name: "Antigua and Barbuda", title: "+1268", code: "AG" },
  { name: "Argentina", title: "+54", code: "AR" },
  { name: "Armenia", title: "+374", code: "AM" },
  { name: "Aruba", title: "+297", code: "AW" },
  { name: "Australia", title: "+61", code: "AU" },
  { name: "Austria", title: "+43", code: "AT" },
  { name: "Azerbaijan", title: "+994", code: "AZ" },
  { name: "Bahamas", title: "+1242", code: "BS" },
  { name: "Bahrain", title: "+973", code: "BH" },
  { name: "Bangladesh", title: "+880", code: "BD" },
  { name: "Barbados", title: "+1246", code: "BB" },
  { name: "Belarus", title: "+375", code: "BY" },
  { name: "Belgium", title: "+32", code: "BE" },
  { name: "Belize", title: "+501", code: "BZ" },
  { name: "Benin", title: "+229", code: "BJ" },
  { name: "Bermuda", title: "+1441", code: "BM" },
  { name: "Bhutan", title: "+975", code: "BT" },
  { name: "Bolivia, Plurinational State of", title: "+591", code: "BO" },
  { name: "Bosnia and Herzegovina", title: "+387", code: "BA" },
  { name: "Botswana", title: "+267", code: "BW" },
  { name: "Brazil", title: "+55", code: "BR" },
  { name: "British Indian Ocean Territory", title: "+246", code: "IO" },
  { name: "Brunei Darussalam", title: "+673", code: "BN" },
  { name: "Bulgaria", title: "+359", code: "BG" },
  { name: "Burkina Faso", title: "+226", code: "BF" },
  { name: "Burundi", title: "+257", code: "BI" },
  { name: "Cambodia", title: "+855", code: "KH" },
  { name: "Cameroon", title: "+237", code: "CM" },
  { name: "Canada", title: "+1", code: "CA" },
  { name: "Cape Verde", title: "+238", code: "CV" },
  { name: "Cayman Islands", title: "+ 345", code: "KY" },
  { name: "Central African Republic", title: "+236", code: "CF" },
  { name: "Chad", title: "+235", code: "TD" },
  { name: "Chile", title: "+56", code: "CL" },
  { name: "China", title: "+86", code: "CN" },
  { name: "Christmas Island", title: "+61", code: "CX" },
  { name: "Cocos (Keeling) Islands", title: "+61", code: "CC" },
  { name: "Colombia", title: "+57", code: "CO" },
  { name: "Comoros", title: "+269", code: "KM" },
  { name: "Congo", title: "+242", code: "CG" },
  {
    name: "Congo, The Democratic Republic of the Congo",
    title: "+243",
    code: "CD",
  },
  { name: "Cook Islands", title: "+682", code: "CK" },
  { name: "Costa Rica", title: "+506", code: "CR" },
  { name: "Cote d'Ivoire", title: "+225", code: "CI" },
  { name: "Croatia", title: "+385", code: "HR" },
  { name: "Cuba", title: "+53", code: "CU" },
  { name: "Cyprus", title: "+357", code: "CY" },
  { name: "Czech Republic", title: "+420", code: "CZ" },
  { name: "Denmark", title: "+45", code: "DK" },
  { name: "Djibouti", title: "+253", code: "DJ" },
  { name: "Dominica", title: "+1767", code: "DM" },
  { name: "Dominican Republic", title: "+1849", code: "DO" },
  { name: "Ecuador", title: "+593", code: "EC" },
  { name: "Egypt", title: "+20", code: "EG" },
  { name: "El Salvador", title: "+503", code: "SV" },
  { name: "Equatorial Guinea", title: "+240", code: "GQ" },
  { name: "Eritrea", title: "+291", code: "ER" },
  { name: "Estonia", title: "+372", code: "EE" },
  { name: "Ethiopia", title: "+251", code: "ET" },
  { name: "Falkland Islands (Malvinas)", title: "+500", code: "FK" },
  { name: "Faroe Islands", title: "+298", code: "FO" },
  { name: "Fiji", title: "+679", code: "FJ" },
  { name: "Finland", title: "+358", code: "FI" },
  { name: "France", title: "+33", code: "FR" },
  { name: "French Guiana", title: "+594", code: "GF" },
  { name: "French Polynesia", title: "+689", code: "PF" },
  { name: "Gabon", title: "+241", code: "GA" },
  { name: "Gambia", title: "+220", code: "GM" },
  { name: "Georgia", title: "+995", code: "GE" },
  { name: "Germany", title: "+49", code: "DE" },
  { name: "Ghana", title: "+233", code: "GH" },
  { name: "Gibraltar", title: "+350", code: "GI" },
  { name: "Greece", title: "+30", code: "GR" },
  { name: "Greenland", title: "+299", code: "GL" },
  { name: "Grenada", title: "+1473", code: "GD" },
  { name: "Guadeloupe", title: "+590", code: "GP" },
  { name: "Guam", title: "+1671", code: "GU" },
  { name: "Guatemala", title: "+502", code: "GT" },
  { name: "Guernsey", title: "+44", code: "GG" },
  { name: "Guinea", title: "+224", code: "GN" },
  { name: "Guinea-Bissau", title: "+245", code: "GW" },
  { name: "Guyana", title: "+595", code: "GY" },
  { name: "Haiti", title: "+509", code: "HT" },
  { name: "Holy See (Vatican City State)", title: "+379", code: "VA" },
  { name: "Honduras", title: "+504", code: "HN" },
  { name: "Hong Kong", title: "+852", code: "HK" },
  { name: "Hungary", title: "+36", code: "HU" },
  { name: "Iceland", title: "+354", code: "IS" },
  { name: "India", title: "+91", code: "IN" },
  { name: "Indonesia", title: "+62", code: "ID" },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    title: "+98",
    code: "IR",
  },
  { name: "Iraq", title: "+964", code: "IQ" },
  { name: "Ireland", title: "+353", code: "IE" },
  { name: "Isle of Man", title: "+44", code: "IM" },
  { name: "Israel", title: "+972", code: "IL" },
  { name: "Italy", title: "+39", code: "IT" },
  { name: "Jamaica", title: "+1876", code: "JM" },
  { name: "Japan", title: "+81", code: "JP" },
  { name: "Jersey", title: "+44", code: "JE" },
  { name: "Jordan", title: "+962", code: "JO" },
  { name: "Kazakhstan", title: "+77", code: "KZ" },
  { name: "Kenya", title: "+254", code: "KE" },
  { name: "Kiribati", title: "+686", code: "KI" },
  {
    name: "Korea, Democratic People's Republic of Korea",
    title: "+850",
    code: "KP",
  },
  { name: "Korea, Republic of South Korea", title: "+82", code: "KR" },
  { name: "Kuwait", title: "+965", code: "KW" },
  { name: "Kyrgyzstan", title: "+996", code: "KG" },
  { name: "Laos", title: "+856", code: "LA" },
  { name: "Latvia", title: "+371", code: "LV" },
  { name: "Lebanon", title: "+961", code: "LB" },
  { name: "Lesotho", title: "+266", code: "LS" },
  { name: "Liberia", title: "+231", code: "LR" },
  { name: "Libyan Arab Jamahiriya", title: "+218", code: "LY" },
  { name: "Liechtenstein", title: "+423", code: "LI" },
  { name: "Lithuania", title: "+370", code: "LT" },
  { name: "Luxembourg", title: "+352", code: "LU" },
  { name: "Macao", title: "+853", code: "MO" },
  { name: "Macedonia", title: "+389", code: "MK" },
  { name: "Madagascar", title: "+261", code: "MG" },
  { name: "Malawi", title: "+265", code: "MW" },
  { name: "Malaysia", title: "+60", code: "MY" },
  { name: "Maldives", title: "+960", code: "MV" },
  { name: "Mali", title: "+223", code: "ML" },
  { name: "Malta", title: "+356", code: "MT" },
  { name: "Marshall Islands", title: "+692", code: "MH" },
  { name: "Martinique", title: "+596", code: "MQ" },
  { name: "Mauritania", title: "+222", code: "MR" },
  { name: "Mauritius", title: "+230", code: "MU" },
  { name: "Mayotte", title: "+262", code: "YT" },
  { name: "Mexico", title: "+52", code: "MX" },
  {
    name: "Micronesia, Federated States of Micronesia",
    title: "+691",
    code: "FM",
  },
  { name: "Moldova", title: "+373", code: "MD" },
  { name: "Monaco", title: "+377", code: "MC" },
  { name: "Mongolia", title: "+976", code: "MN" },
  { name: "Montenegro", title: "+382", code: "ME" },
  { name: "Montserrat", title: "+1664", code: "MS" },
  { name: "Morocco", title: "+212", code: "MA" },
  { name: "Mozambique", title: "+258", code: "MZ" },
  { name: "Myanmar", title: "+95", code: "MM" },
  { name: "Namibia", title: "+264", code: "NA" },
  { name: "Nauru", title: "+674", code: "NR" },
  { name: "Nepal", title: "+977", code: "NP" },
  { name: "Netherlands", title: "+31", code: "NL" },
  { name: "Netherlands Antilles", title: "+599", code: "AN" },
  { name: "New Caledonia", title: "+687", code: "NC" },
  { name: "New Zealand", title: "+64", code: "NZ" },
  { name: "Nicaragua", title: "+505", code: "NI" },
  { name: "Niger", title: "+227", code: "NE" },
  { name: "Nigeria", title: "+234", code: "NG" },
  { name: "Niue", title: "+683", code: "NU" },
  { name: "Norfolk Island", title: "+672", code: "NF" },
  { name: "Northern Mariana Islands", title: "+1670", code: "MP" },
  { name: "Norway", title: "+47", code: "NO" },
  { name: "Oman", title: "+968", code: "OM" },
  { name: "Pakistan", title: "+92", code: "PK" },
  { name: "Palau", title: "+680", code: "PW" },
  { name: "Palestinian Territory, Occupied", title: "+970", code: "PS" },
  { name: "Panama", title: "+507", code: "PA" },
  { name: "Papua New Guinea", title: "+675", code: "PG" },
  { name: "Paraguay", title: "+595", code: "PY" },
  { name: "Peru", title: "+51", code: "PE" },
  { name: "Philippines", title: "+63", code: "PH" },
  { name: "Pitcairn", title: "+872", code: "PN" },
  { name: "Poland", title: "+48", code: "PL" },
  { name: "Portugal", title: "+351", code: "PT" },
  { name: "Puerto Rico", title: "+1939", code: "PR" },
  { name: "Qatar", title: "+974", code: "QA" },
  { name: "Romania", title: "+40", code: "RO" },
  { name: "Russia", title: "+7", code: "RU" },
  { name: "Rwanda", title: "+250", code: "RW" },
  { name: "Reunion", title: "+262", code: "RE" },
  { name: "Saint Barthelemy", title: "+590", code: "BL" },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    title: "+290",
    code: "SH",
  },
  { name: "Saint Kitts and Nevis", title: "+1869", code: "KN" },
  { name: "Saint Lucia", title: "+1758", code: "LC" },
  { name: "Saint Martin", title: "+590", code: "MF" },
  { name: "Saint Pierre and Miquelon", title: "+508", code: "PM" },
  { name: "Saint Vincent and the Grenadines", title: "+1784", code: "VC" },
  { name: "Samoa", title: "+685", code: "WS" },
  { name: "San Marino", title: "+378", code: "SM" },
  { name: "Sao Tome and Principe", title: "+239", code: "ST" },
  { name: "Saudi Arabia", title: "+966", code: "SA" },
  { name: "Senegal", title: "+221", code: "SN" },
  { name: "Serbia", title: "+381", code: "RS" },
  { name: "Seychelles", title: "+248", code: "SC" },
  { name: "Sierra Leone", title: "+232", code: "SL" },
  { name: "Singapore", title: "+65", code: "SG" },
  { name: "Slovakia", title: "+421", code: "SK" },
  { name: "Slovenia", title: "+386", code: "SI" },
  { name: "Solomon Islands", title: "+677", code: "SB" },
  { name: "Somalia", title: "+252", code: "SO" },
  { name: "South Africa", title: "+27", code: "ZA" },
  { name: "South Sudan", title: "+211", code: "SS" },
  {
    name: "South Georgia and the South Sandwich Islands",
    title: "+500",
    code: "GS",
  },
  { name: "Spain", title: "+34", code: "ES" },
  { name: "Sri Lanka", title: "+94", code: "LK" },
  { name: "Sudan", title: "+249", code: "SD" },
  { name: "Suriname", title: "+597", code: "SR" },
  { name: "Svalbard and Jan Mayen", title: "+47", code: "SJ" },
  { name: "Swaziland", title: "+268", code: "SZ" },
  { name: "Sweden", title: "+46", code: "SE" },
  { name: "Switzerland", title: "+41", code: "CH" },
  { name: "Syrian Arab Republic", title: "+963", code: "SY" },
  { name: "Taiwan", title: "+886", code: "TW" },
  { name: "Tajikistan", title: "+992", code: "TJ" },
  {
    name: "Tanzania, United Republic of Tanzania",
    title: "+255",
    code: "TZ",
  },
  { name: "Thailand", title: "+66", code: "TH" },
  { name: "Timor-Leste", title: "+670", code: "TL" },
  { name: "Togo", title: "+228", code: "TG" },
  { name: "Tokelau", title: "+690", code: "TK" },
  { name: "Tonga", title: "+676", code: "TO" },
  { name: "Trinidad and Tobago", title: "+1868", code: "TT" },
  { name: "Tunisia", title: "+216", code: "TN" },
  { name: "Turkey", title: "+90", code: "TR" },
  { name: "Turkmenistan", title: "+993", code: "TM" },
  { name: "Turks and Caicos Islands", title: "+1649", code: "TC" },
  { name: "Tuvalu", title: "+688", code: "TV" },
  { name: "Uganda", title: "+256", code: "UG" },
  { name: "Ukraine", title: "+380", code: "UA" },
  { name: "United Arab Emirates", title: "+971", code: "AE" },
  { name: "United Kingdom", title: "+44", code: "GB" },
  { name: "United States", title: "+1", code: "US" },
  { name: "Uruguay", title: "+598", code: "UY" },
  { name: "Uzbekistan", title: "+998", code: "UZ" },
  { name: "Vanuatu", title: "+678", code: "VU" },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    title: "+58",
    code: "VE",
  },
  { name: "Vietnam", title: "+84", code: "VN" },
  { name: "Virgin Islands, British", title: "+1284", code: "VG" },
  { name: "Virgin Islands, U.S.", title: "+1340", code: "VI" },
  { name: "Wallis and Futuna", title: "+681", code: "WF" },
  { name: "Yemen", title: "+967", code: "YE" },
  { name: "Zambia", title: "+260", code: "ZM" },
  { name: "Zimbabwe", title: "+263", code: "ZW" },
];
