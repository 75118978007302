import "./App.css";
import "../src/asserts/fonts.css";
import LandingPage from "Containers/Views/LandingPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <LandingPage />
    </div>
  );
};

export default App;
