import React from "react";
import Modal from "Components/Modal";
import styled from "styled-components";

import ContactForm from "./ContactForm";

export const ModalContainer = styled.div``;

const SuccessModal = ({ modal, toggle }) => {
  return (
    <Modal
      modal={modal}
      toggle={toggle}
      className="agreement-success-modal"
      content={
        <ModalContainer>
          <ContactForm className="modal-form" />
        </ModalContainer>
      }
    />
  );
};

export default SuccessModal;
