import {
  emailPattern,
  phonePattern,
  InvalidEmail,
  InvalidPhone,
  validPhoneNumber,
} from "utils/constants";

const renderEmailValidation = (email) => {
  let isValid = true;
  let error = "";

  if (!email) {
    isValid = false;
    error = "Email is required";
  } else if (!emailPattern.test(email)) {
    isValid = false;
    error = "Entered email is not valid";
  }
  return { isValid, error };
};

export const handleValidationContactForm = (props) => {
  let errors = {};
  let formIsValid = true;

  if (!props.firstName) {
    formIsValid = false;
    errors.firstName = "First name is required";
  }

  if (!props.lastName) {
    formIsValid = false;
    errors.lastName = "Last name is required";
  }

  const errEmail = renderEmailValidation(props.email);
  if (!errEmail.isValid) {
    formIsValid = false;
    errors.email = errEmail.error;
  }

  if (!props.phoneNumber) {
    formIsValid = false;
    errors.phoneNumber = "Phone is required";
  } else if (!props.phoneNumber.match(validPhoneNumber)) {
    formIsValid = false;
    errors.phoneNumber = "Entered phone number is not correct";
  }

  if (!props.message) {
    formIsValid = false;
    errors.message = "Please enter your message here";
  }

  return { errors, formIsValid };
};
