import styled from "styled-components";

const Container = styled.div`
  padding: 18px 0px 18px 0px;
  text-align: center;
  font: normal 20px Montserrat;
  letter-spacing: 0px;
  color: #ababab;
  opacity: 1;
  @media (max-width: 768px) and (min-width: 550px) {
    font-size: 15px;
  }
  @media (max-width: 549px) and (min-width: 320px) {
    font-size: 13px;
  }
`;

export default Container;
